import "core-js/modules/es.array.push.js";
import AdaptiveSelected from '@/components/Basic/AdaptiveSelect.vue';
import IdeTable from '@/components/MyTable/IdeTable.vue';
import { ElLoading } from 'element-plus';
import { Search, ArrowRight } from '@element-plus/icons-vue';
import { ref, computed, watch, nextTick, reactive } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { getSupportType, getSupportList, getSupportDownloadUrl } from '@/service/index.js';
import { alertMessage, downloadFile } from '@/assets/js/tool.js';
export default {
  components: {
    Search,
    ArrowRight,
    AdaptiveSelected,
    IdeTable
  },
  setup() {
    //store
    const store = useStore();
    //computed
    const lang = computed(() => store.getters.getLang);
    //data
    const {
      t
    } = useI18n();
    const select = ref('all');
    const options = ref({
      zh: [],
      en: [],
      now: [] //当前展示
    });

    const search = ref('');
    const page = ref(1); //当前分页
    const total = ref(1); //共多少页
    const list = ref([]); //列表
    const noMore = ref(false); //没有更多数据
    const column = ref([{
      title: () => t('message.Type'),
      width: '160px',
      template: row => {
        let key = lang.value === 'en' ? 'enName' : 'zhName';
        let type = row.type || {};
        return type[key] || '';
      }
    }, {
      title: () => t('message.PublishDate'),
      width: '280px',
      template: row => {
        return row.createTime ? row.createTime.split(' ')[0] : '';
      }
    }, {
      title: () => t('message.Documentation'),
      width: '510px',
      more: true,
      template: row => {
        let key = lang.value === 'en' ? 'enRemark' : 'zhRemark';
        let remark = row[key] ? row[key] : '';
        return getHtml(remark);
      }
    }, {
      title: () => t('message.File'),
      width: '302px',
      template: row => {
        let key = lang.value === 'en' ? 'enName' : 'zhName';
        return row[key] ? row[key] : '';
      }
    }, {
      title: '',
      width: '100px',
      operation: () => t('message.download')
    }]);
    const params = ref({}); //列表搜索参数

    //ref
    const moreContentRef = ref(null);
    const ideTableRef = ref(null);

    //created
    initOptions();
    initSupportList();

    //watch
    //切换多语言强制刷新select
    watch(lang, async () => {
      options.value.now = [];
      await nextTick();
      options.value.now = JSON.parse(JSON.stringify(options.value[lang.value]));
      ideTableRef.value.initEllipsis();
    });
    //表格数据变化
    watch(() => list.value, async () => {
      await nextTick();
      ideTableRef.value.initEllipsis();
      ideTableRef.value.scrollDisabled = false;
    });

    //初始化下拉选项
    async function initOptions() {
      let result = await getSupportType();
      if (result && result.status === 0) {
        let rows = result.rows || [];
        let zhArr = [{
          label: '全部分类',
          value: 'all'
        }];
        let enArr = [{
          label: 'All Type',
          value: 'all'
        }];
        rows.forEach(item => {
          zhArr.push({
            label: item.zhName,
            value: item.id
          });
          enArr.push({
            label: item.enName,
            value: item.id
          });
        });
        options.value.zh = zhArr;
        options.value.en = enArr;
        options.value.now = JSON.parse(JSON.stringify(options.value[lang.value]));
      } else {
        let text = result ? result.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    }

    //初始化列表
    async function initSupportList(params = {}) {
      noMore.value = false;
      params.page = page.value;
      params.rows = 20;
      let loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      let result = await getSupportList(params);
      loading.close();
      if (result && result.status === 0) {
        total.value = result.total || 1;
        let rows = result.rows || [];
        list.value = [...list.value, ...rows];
      } else {
        let text = result ? result.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    }

    //加载更多
    function handleLoadMore() {
      page.value++;
      if (page.value > total.value) {
        noMore.value = true;
        return;
      }
      initSupportList();
    }

    //选择了下拉选项
    function handleSelectChange(val) {
      let paramsVal = params.value;
      if (val === 'all') {
        delete paramsVal['Q_EQ_type.id'];
      } else {
        paramsVal['Q_EQ_type.id'] = val;
      }
      page.value = 1;
      list.value = [];
      ideTableRef.value.initScrollTop();
      initSupportList(paramsVal);
    }

    //输入框搜索
    function handleSearchInput(val) {
      let paramsVal = params.value;
      if (val.trim() === '') {
        delete paramsVal['QV_enName'];
        delete paramsVal['QV_zhName'];
      } else {
        paramsVal['QV_enName'] = val;
        paramsVal['QV_zhName'] = val;
      }
      page.value = 1;
      list.value = [];
      ideTableRef.value.initScrollTop();
      initSupportList(paramsVal);
    }

    //获取文本
    function getHtml(text) {
      return text.replace(/\n/g, '<br/>');
    }

    //点击下载
    async function handleClickDownload(row) {
      let result = await getSupportDownloadUrl({
        id: row.id
      });
      if (result && result.status === 0) {
        let url = result.data;
        let name = lang.value === 'en' ? row.enName : row.zhName;
        downloadFile(url, name);
      } else {
        let errorMsg = result ? result.msg || t('message.OperationFailed') : t('message.OperationFailed');
        alertMessage('error', errorMsg);
      }
    }
    return {
      //data
      lang,
      select,
      options,
      search,
      list,
      column,
      noMore,
      //ref
      moreContentRef,
      ideTableRef,
      //function
      handleLoadMore,
      handleSelectChange,
      handleSearchInput,
      getHtml,
      handleClickDownload
    };
  }
};