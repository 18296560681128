import { ref } from 'vue';
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue';
export default {
  components: {
    ArrowDown,
    ArrowUp
  },
  props: {
    //v-model的值
    modelValue: String,
    options: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, context) {
    //data
    //是否显示下拉选项
    const showOptions = ref(false);
    const selected = ref({});
    selected.value = props.options.find(item => item.value === props.modelValue);

    //function
    //选择下拉选项
    function handleSelect(item) {
      selected.value = item;
      context.emit('update:modelValue', item.value);
      showOptions.value = false;
      context.emit('change', item.value);
    }
    return {
      //data
      showOptions,
      selected,
      //function
      handleSelect
    };
  }
};