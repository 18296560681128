import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9139c774"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "select-dropdown"
};
const _hoisted_2 = ["title"];
const _hoisted_3 = {
  key: 0,
  class: "select-meun"
};
const _hoisted_4 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_arrow_down = _resolveComponent("arrow-down");
  const _component_arrow_up = _resolveComponent("arrow-up");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", {
    class: "adaptive-select-box",
    onMouseenter: _cache[0] || (_cache[0] = $event => $setup.showOptions = true),
    onMouseleave: _cache[1] || (_cache[1] = $event => $setup.showOptions = false)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", {
    title: $setup.selected.label
  }, _toDisplayString($setup.selected.label), 9, _hoisted_2), $setup.selected.icon ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["iconfont", $setup.selected.icon])
  }, null, 2)) : _createCommentVNode("", true), _createVNode(_component_el_icon, {
    class: "icon-right"
  }, {
    default: _withCtx(() => [!$setup.showOptions ? (_openBlock(), _createBlock(_component_arrow_down, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_arrow_up, {
      key: 1
    }))]),
    _: 1
  })]), $setup.showOptions ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["menu-item", {
        check: item.value === $setup.selected.value
      }]),
      key: index,
      title: item.label,
      onClick: $event => $setup.handleSelect(item)
    }, [_createTextVNode(_toDisplayString(item.label) + " ", 1), item.icon ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass(["iconfont", item.icon])
    }, null, 2)) : _createCommentVNode("", true)], 10, _hoisted_4);
  }), 128))])) : _createCommentVNode("", true)], 32);
}